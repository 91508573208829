// libs
import gsap from "gsap";


export default class CarteHover
{
	constructor(elmt) {
		this.el = elmt
    }

    actions() {
        this.el.addEventListener('mouseenter', () => {
            this.el.classList.add('actif');

            gsap.ticker.add(this.addTicker);
        })
        this.el.addEventListener('mouseleave', () => {
            this.el.classList.remove('actif');

            gsap.delayedCall(0.5, () => {
                if(!this.el.classList.contains('actif')){
                    gsap.ticker.remove(this.addTicker);
                }
            })
            
        })

        this.addTicker = () => {
            this.playTicker();
        }


        gsap.set(this.el.querySelector('.rond'), {xPercent: -50, yPercent: -50});

        const ball = this.el.querySelector('.rond');
        this.pos = { x: 0, y: 0 };

        this.xSet = gsap.quickSetter(ball, "x", "px");
        this.ySet = gsap.quickSetter(ball, "y", "px");
    }

    playTicker(){
        const dt = 1.0 - Math.pow(0.9, gsap.ticker.deltaRatio());
        
        this.pos.x += (mouse.x - this.pos.x - this.el.getBoundingClientRect().left) * dt;
        this.pos.y += (mouse.y - this.pos.y - this.el.getBoundingClientRect().top) * dt;
        this.xSet(this.pos.x);
        this.ySet(this.pos.y);
    }
}