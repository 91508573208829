export function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}


export function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
};


export function indexInParent(node) {
    var children = node.parentNode.childNodes;
    var num = 0;
    for (var i=0; i<children.length; i++) {
         if (children[i]==node) {return num;}
         if (children[i].nodeType==1) {num++;}
    }
    return -1;
}


export function hypothenuse(a, b) {
    return Math.sqrt(Math.pow(Math.abs(a), 2) + Math.pow(Math.abs(b), 2));

}

export function appHeight() {
    document.documentElement.style.setProperty('--app-height', window.innerHeight+'px')
}


export function simulateClick(elem) {
    // Create our event (with options)
    var evt = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
    });
    // If cancelled, don't dispatch our event
    var canceled = !elem.dispatchEvent(evt);
}


export function isTouch() {
    if('ontouchstart' in window || navigator.msMaxTouchPoints){
        return true;
    }else{
        return false;
    }
}

export function getAllSiblings(element) {
    const children = [...element.parentElement.children];
    return children.filter(child => child !== element);
}


export function isChild (obj,parentObj){
    while (obj != undefined && obj != null && obj.tagName.toUpperCase() != 'BODY'){
        if (obj == parentObj){
            return true;
        }
        obj = obj.parentNode;
    }
    return false;
}


export function splitLines(container, opentag, closingtag) {
    var spans = container.children,
        top = 0,
        tmp = '';

    container.innerHTML = container.textContent.replace(/\S+/g, '<n>$&</n>');      
    for (let i = 0; i < spans.length; i++) {
        var rect = spans[i].getBoundingClientRect().top;
        if (top < rect){
            tmp += closingtag + opentag;
        }
        top = rect;
        tmp += spans[i].textContent + ' ';
    }
    container.innerHTML = tmp += closingtag;
}