// libs
import gsap from "gsap"
import CarteHover from "CarteHover"


export default class Carte
{
	constructor(elmt) {
		this.elmt = elmt;
		this.currentPos = 0;
		this.nbCartes = elmt.querySelectorAll('.uneCarte').length / 3;

		this.maxGauche = this.nbCartes - 2;

        this.animEnC = false;
    }

    actions () {
    	console.log(this.nbCartes, this.elmt.querySelector('.carte'));

    	this.elmt.querySelector('.next').addEventListener('click', () => {
    		if(this.animEnC == false){ //this.currentPos < this.maxGauche && 
	    		this.currentPos++;
	    		this.deplacement();
	    	}
    	})
    	this.elmt.querySelector('.prev').addEventListener('click', () => {
    		if(this.animEnC == false){ //this.currentPos > 0  && 
	    		this.currentPos--;
	    		this.deplacement();
	    	}
    	})

    	//
    	// partie hover rond
    	//
    	document.querySelectorAll('.uneCarte').forEach(el => {
    		const carteHover = new CarteHover(el);
            carteHover.actions();
    	})


    }

    deplacement() {
        this.animEnC = true;
    	gsap.to(this.elmt.querySelector('.cartes'), {
			x: -1 * this.currentPos * document.querySelector('.innerCarte').clientWidth + "px",
			ease:"easeSpe",
			duration:0.5,
            onComplete:() => {
                console.log('tes sososso')
                if(this.currentPos == this.nbCartes || this.currentPos == -this.nbCartes){
                    gsap.set(this.elmt.querySelector('.cartes'), {x:0});
                    this.currentPos = 0;
                }

                this.animEnC = false;
            }
		})
    }

}