import gsap from "gsap";
import { CustomEase } from "gsap/CustomEase";
import ScrollTrigger from "gsap/ScrollTrigger";
// import Lottie from "lottie-web"

import Transition from "Transition"
import {isTouch, indexInParent, appHeight} from "utils";

import {lottieBurger, isPopUpAnimating} from "./constants";

gsap.registerPlugin(CustomEase)
gsap.registerPlugin(ScrollTrigger)


const transition = new Transition()

CustomEase.create("easeSpe", "0.90, 0.00, 0.10, 1.00");

document.addEventListener("DOMContentLoaded", function() {
    
    appHeight();

    // const lottieBurger = Lottie.loadAnimation({
    //     container: document.querySelector('.toMenu'), // the dom element that will contain the animation
    //     renderer: 'svg',
    //     autoplay: false,
    //     loop:false,
    //     path: document.querySelector('.toMenu').getAttribute('data-lottie') // the path to the animation json
    // });

    let lastPos = 0;
    window.addEventListener('scroll', (event) =>{
        if(window.innerWidth > 1024){
            if(lastPos > window.pageYOffset && !document.querySelector('.fondH').classList.contains('actif') && window.pageYOffset > 0){
                document.querySelector('.fondH').classList.add('actif');
                document.querySelector('header').classList.add('cache2');
                
                if(document.body.classList.contains('home')){
                    document.querySelector('header a.cta').classList.remove('blanc');
                }
            }else if(lastPos < window.pageYOffset && document.querySelector('.fondH').classList.contains('actif') && window.pageYOffset > 0){
                document.querySelector('.fondH').classList.remove('actif');
                document.querySelector('header').classList.remove('cache2');
            }

            if(window.pageYOffset > 0 && !document.querySelector('header').classList.contains('cache')){
                document.querySelector('header').classList.add('cache');
            }

            if(window.pageYOffset <= 0 && document.querySelector('.fondH').classList.contains('actif')){
                console.log('une fois')
                document.querySelector('.fondH').classList.remove('actif');
                document.querySelector('header').classList.remove('cache2');
                document.querySelector('header').classList.remove('cache');
                
                if(document.body.classList.contains('home')){
                    document.querySelector('header a.cta').classList.add('blanc');
                }
            }

        }else if(!document.querySelector('header').classList.contains('actif')){
            if(lastPos > window.pageYOffset && !document.querySelector('.fondH').classList.contains('actif') && window.pageYOffset > 0){
                document.querySelector('.fondH').classList.add('actif');
                document.querySelector('.headerMob').classList.add('cache2');

                if(document.body.classList.contains('home')){
                    document.querySelector('.headerMob button').classList.remove('blanc');
                }
            }else if(lastPos < window.pageYOffset && document.querySelector('.fondH').classList.contains('actif') && window.pageYOffset > 0){
                document.querySelector('.fondH').classList.remove('actif');
                document.querySelector('.headerMob').classList.remove('cache2');
            }

            if(window.pageYOffset > 0 && !document.querySelector('header').classList.contains('cache')){
                document.querySelector('.headerMob').classList.add('cache');
            }

            if(window.pageYOffset <= 0 && document.querySelector('.fondH').classList.contains('actif')){
                console.log('une fois')
                document.querySelector('.fondH').classList.remove('actif');
                document.querySelector('.headerMob').classList.remove('cache2');
                document.querySelector('.headerMob').classList.remove('cache');
                
                
                if(document.body.classList.contains('home')){
                    document.querySelector('.headerMob button').classList.add('blanc');
                }
            }
        }

        lastPos = window.pageYOffset;
    });

	
    //
    //
	// first animation debut loader
    document.body.classList.add('bloque')
    window.scrollTo(0,0)
    transition.start(window.location.href, true);
    // fin first
    //
    //

	// click event
	document.addEventListener('click', function (event) {
		//
		// si lien
		//
		if (event.target.closest('a') && !event.target.closest('a').classList.contains('extern')) {
            event.preventDefault();
            if(!transition.animEnCours){
                
				if(event.target.closest('a').getAttribute('href').indexOf("patient") > -1) {
					console.log('to patient');
					document.body.classList.add('toPatient')
				}else if(event.target.closest('a').getAttribute('href').indexOf("provider") > -1) {
					console.log('to provider');
					document.body.classList.add('toProvider')
				}else if(event.target.closest('a').getAttribute('href').indexOf("our-plans") > -1) {
					console.log('to plans');
					document.body.classList.add('toPlan')
				}else if(event.target.closest('a').getAttribute('href') == document.querySelector('header .toHome').getAttribute('href')){
					console.log('to home');
					document.body.classList.add('toHome')
				}else if(event.target.closest('a').getAttribute('href').indexOf("stories") > -1){
                    console.log('to stories');
                    document.body.classList.add('toStories')
                }else{
                    console.log('to page normale')
                    document.body.classList.add('toPage')
                }

				transition.start(event.target.closest('a').getAttribute('href'));
                history.pushState({}, '', event.target.closest('a').getAttribute('href'));
            }
        //
        // fin si lien
        //
        }else if (event.target.closest('.toNews') && !isPopUpAnimating.state) {
            isPopUpAnimating.state = true;
            document.body.classList.add('bloque');
            document.querySelector('.newsletter').classList.add('actif');
            apparitionPopUp(document.querySelector('.newsletter'));
        }if (event.target.closest('.toContact') && !isPopUpAnimating.state) {
            isPopUpAnimating.state = true;
            document.body.classList.add('bloque');
            document.querySelector('.contactUs').classList.add('actif');
            apparitionPopUp(document.querySelector('.contactUs'));
        }if (event.target.closest('.uneCarte') && !isPopUpAnimating.state) {
            isPopUpAnimating.state = true;
            document.body.classList.add('bloque');
            
            let numCarte = indexInParent(event.target.closest('.innerCarte'))
            let laPopUp = event.target.closest('.uneCarte').parentElement.parentElement.parentElement.querySelectorAll('.lesPopUps .popCarte')[numCarte];

            if(laPopUp == undefined){
                laPopUp = event.target.closest('.uneCarte').parentElement.parentElement.parentElement.parentElement.querySelectorAll('.lesPopUps .popCarte')[numCarte];
            }
            
            laPopUp.classList.add('actif');
            apparitionPopUp(laPopUp);
        
        }else if (event.target.closest('.pushBlessure') && !isPopUpAnimating.state) {
            isPopUpAnimating.state = true;
            document.body.classList.add('bloque');
            
            let numCarte = indexInParent(event.target.closest('.pushBlessure'))
            let laPopUp = event.target.closest('.pushBlessure').parentElement.parentElement.querySelectorAll('.lesPopUps .popCarte')[numCarte];
            
            laPopUp.classList.add('actif');
            apparitionPopUp(laPopUp);
        
        }else if (
            (event.target.closest('.toClose') || event.target.closest('.popUp .fond'))
            && !isPopUpAnimating.state
        ) {
            document.body.classList.remove('bloque');
            isPopUpAnimating.state = true;

            gsap.to('.popUp.actif .efface', {opacity:0, duration:0.3, ease:"none"})
            // event.target.closest('.popUp').querySelector('.droite').style.display ="none"
            
            tl.reverse()
            gsap.delayedCall(1.2, () => {
                gsap.set('.popUp.actif .efface', {clearProps:"all"})
            	event.target.closest('.popUp').classList.remove('actif');
            	tl.kill();
                isPopUpAnimating.state = false;
            })
        }else if(event.target.closest('.innerSelect') && !isTouch()) {
        	document.querySelectorAll('.innerSelect').forEach(el => {el.classList.remove('devant')})
        	event.target.closest('.innerSelect').classList.add('devant');
        	document.querySelectorAll('.fauxSelect').forEach(el => {el.classList.remove('actif')})
        	event.target.closest('.innerSelect').querySelector('.fauxSelect').classList.add('actif');
        }else if(event.target.closest('[data-toggle-trigger]')) {

            document.querySelectorAll('[data-toggle-trigger]').forEach(el2 => {
                if(el2 != event.target.closest('[data-toggle-trigger]')){
                    el2.classList.remove('actif')
                }
                gsap.to(el2.parentElement, {
                    height: el2.parentElement.getAttribute('data-hauteur') + "px",
                    ease:"easeSpe", duration:0.5
                })
            })

            const el = event.target.closest('[data-toggle-trigger]');
            el.classList.toggle('actif');

            const elList = el.parentElement;
            const headerHeight = el.clientHeight;
            const reponseHeight = el.parentElement.querySelector('[data-toggle-panel]').clientHeight;



            if(el.classList.contains('actif')){
                gsap.to(elList, {height: reponseHeight + headerHeight + 'px', ease:"easeSpe", duration:0.5})
            }else{
                gsap.to(elList, {height: headerHeight + 'px', ease:"easeSpe", duration:0.5})
            }

        }else if(event.target.closest('.explore')) {
            const el = event.target.closest('.explore');
            el.classList.toggle('actif');

            const elList = el.parentElement.parentElement;
            const reponseHeight = elList.querySelector('p span').offsetHeight;

            if(el.classList.contains('actif')){

                gsap.to(elList.querySelector('p'), {height: reponseHeight + 'px', ease:"easeSpe", duration:0.8})
                gsap.to(event.target.closest('.explore').querySelector('.text div'), {
                    y:'-100%', duration:0.8, ease:"easeSpe"
                })
                gsap.to(event.target.closest('.explore').querySelector('.l2'), {
                    scaleY:0, duration:0.8, ease:"easeSpe"
                })
            }else{
                gsap.to(elList.querySelector('p'), {height: '0px', ease:"easeSpe", duration:0.8})
                gsap.to(event.target.closest('.explore').querySelector('.text div'), {
                    y:'0%', duration:0.8, ease:"easeSpe"
                })
                gsap.to(event.target.closest('.explore').querySelector('.l2'), {
                    scaleY:1, duration:0.8, ease:"easeSpe"
                })
            }

        }else if(event.target.closest('.filtre')){

            const el = event.target.closest('.filtre')
            const filtre = el.getAttribute('data-slug')

            document.querySelector('.filtre.current').classList.remove('current')
            el.classList.add('current')
            
            if(filtre == 'all'){
                document.querySelectorAll('.unArticle').forEach(el => {el.style.display = "flex"})
            }else{
                document.querySelectorAll('.unArticle').forEach(el => {el.style.display = "none"})
                document.querySelectorAll('.unArticle[data-slug='+filtre+']').forEach(el => {el.style.display = "flex"})
            }
        }else if(event.target.closest('.toMenu')){
            if(!document.querySelector('header').classList.contains('actif')){

                document.querySelector('.headerMob button').classList.remove('blanc');

                document.querySelector('header').classList.add('actif')
                document.querySelector('.headerMob a').classList.add('cache')

                gsap.to('header', {background:'rgba(30,28,33,0.7)', duration:0.5})
                gsap.to('.fondMob, nav', {x:'0%', ease:"easeSpe", duration:1})

                lottieBurger.setDirection(1);
                lottieBurger.play();
            }else{

                gsap.to('header', {background:'rgba(30,28,33,0)', duration:0.5})
                gsap.to('.fondMob, nav', {x:'100%', ease:"easeSpe", duration:1, onComplete:() =>{
                    if(window.pageYOffset == 0 && document.querySelector('body.home')){
                        document.querySelector('.headerMob button').classList.add('blanc');
                    }

                    document.querySelector('header').classList.remove('actif')
                    document.querySelector('.headerMob a').classList.remove('cache')
                }})

                lottieBurger.setDirection(-1);
                lottieBurger.play();
            }
        }else if(event.target.closest('.carou .innerImg')){
            nextImgSingle(event.target.closest('.carou'));
        }

        if(event.target.closest('.uneOption')) {
        	event.target.closest('.uneOption').parentElement.classList.remove('actif');
        	document.querySelectorAll('.innerSelect').forEach(el => {el.classList.remove('devant')})
        	event.target.closest('.uneOption').parentElement.parentElement.querySelector('select').value = event.target.closest('.uneOption').getAttribute('data-value');
        }

        if(!event.target.closest('.innerSelect')) {
        	document.querySelectorAll('.fauxSelect').forEach(el => {el.classList.remove('actif')})
        }

        if(event.target.closest(".fleche")){
            const progress = {};
            progress.scroll = window.pageYOffset;

            gsap.to(progress, {
                scroll: window.innerHeight,
                duration:1,
                ease:'easeSpe',
                onUpdate:() => {
                    window.scrollTo(0, progress.scroll)
                }
            })
        }

        // if(event.target.closest('#submitNews')) {
        //     event.preventDefault();
        //     console.log('all')
        // }
    })	


    document.querySelector('.formNews').addEventListener('submit', (event) => {
        event.preventDefault();

        let formData;
        formData =  'c_name='+encodeURIComponent(document.querySelector('input[name="news-name"]').value);
        formData += '&c_iam='+encodeURIComponent(document.querySelector('select[name="news-iam"]').value);
        formData += '&c_email='+encodeURIComponent(document.querySelector('input[name="news-email"]').value);

        const request = new XMLHttpRequest();
        request.open('GET', '?alrightNews&' + formData, true);
        request.onload = () => { 
            if(request.status >= 200 && request.status < 400){
                gsap.to('.formNews button', {
                    opacity:0,duration:0.2,ease:"power2.inOut",
                    onComplete: () => {
                        document.querySelector('.formNews button').style.display = "none";
                        document.querySelector('.formNews .valid').classList.add('actif');
                        gsap.to('.formNews .valid', {opacity:1,duration:0.2,delay:0.1,ease:"power2.inOut"})
                    }
                })
            }
        }
        request.send();

        return false;
    });

    document.querySelector('.formContact').addEventListener('submit', (event) => {
        event.preventDefault();

        let formData;
        formData =  'c_firstname='+encodeURIComponent(document.querySelector('input[name="contact-firstname"]').value);
        formData += '&c_lastname='+encodeURIComponent(document.querySelector('input[name="contact-lastname"]').value);
        formData += '&c_email='+encodeURIComponent(document.querySelector('input[name="contact-email"]').value);
        formData += '&c_tel='+encodeURIComponent(document.querySelector('input[name="contact-phone"]').value);
        formData += '&c_type='+encodeURIComponent(document.querySelector('select[name="contact-iam"]').value);
        formData += '&c_from='+encodeURIComponent(document.querySelector('select[name="contact-from"]').value);
        formData += '&c_message='+encodeURIComponent(document.querySelector('textarea[name="contact-message"]').value);

        const request = new XMLHttpRequest();
        request.open('GET', '?alrightContact&' + formData, true);
        request.onload = () => { 
            if(request.status >= 200 && request.status < 400){
                gsap.to('.formContact button', {
                    opacity:0,duration:0.2,ease:"power2.inOut",
                    onComplete: () => {
                        document.querySelector('.formContact button').style.display = "none";
                        document.querySelector('.formContact .valid').classList.add('actif');
                        gsap.to('.formContact .valid', {opacity:1,duration:0.2,delay:0.1,ease:"power2.inOut"})
                    }
                })
            }
        }
        request.send();

        return false;
    });
})

window.addEventListener('popstate', (e) => {
	if(window.location.href.indexOf("patient") > -1) {
		console.log('to patient');
		document.body.classList.add('toPatient')
	}else if(window.location.href.indexOf("provider") > -1) {
		console.log('to provider');
		document.body.classList.add('toProvider')
	}else if(window.location.href.indexOf("our-plans") > -1) {
		console.log('to plans');
		document.body.classList.add('toPlan')
	}else if(window.location.href == document.querySelector('header .toHome').getAttribute('href')){
		console.log('to home');
		document.body.classList.add('toHome')
	}else if(window.location.href.indexOf("stories") > -1){
        console.log('to stories');
        document.body.classList.add('toStories')
    }else{
        console.log('to page normale')
        document.body.classList.add('toPage')
    }

	transition.start(location.href);

}, false);
if (history.scrollRestoration) {
    history.scrollRestoration = 'manual';
}




let tl; 
function apparitionPopUp(el) {
	tl = gsap.timeline();
    tl.to([el.querySelector('.gauche'), el.querySelector('.droite')], {x:0, ease:"easeSpe", duration:1.2}, 0);
    tl.to(el.querySelector('.fond'), {opacity:1, ease:"power4.inOut", duration:1.2, onComplete:() => {
        isPopUpAnimating.state = false;
    }}, 0);

    var soustraction = 0
    if(el == document.querySelector('.newsletter') || el == document.querySelector('.contactUs')){
        soustraction = 0.4;
    }
    if(el.querySelector('.titrePop')){
        gsap.fromTo(el.querySelector('.titrePop span'), {y:'100%'}, {y:'0%', ease:"easeSpe", duration:1.2, delay:0.9})
    }
    if(el.querySelector('hr')){
        gsap.fromTo(el.querySelector('hr'), {scaleX:0}, {scaleX:1, ease:"easeSpe", duration:1.2, delay:0.9})
    }
    gsap.fromTo(el.querySelectorAll('.contenu'), {opacity:0}, {opacity:1, ease:"none", duration:1, delay:1.1 - soustraction})
}


let ZInd = 3;
function nextImgSingle(el){

    const numSlides = el.querySelectorAll('.innerImg').length;

    const currentEl = el.querySelector('.current');
    currentEl.style.zIndex = ZInd;
    ZInd++;

    const nextEl = (el.querySelector('.current').nextElementSibling) ? el.querySelector('.current').nextElementSibling : el.querySelectorAll('.innerImg')[0]
    gsap.set([nextEl, nextEl.querySelector('img')], {x:'0%'})

    const progrBar = el.querySelector('.progressBar div');

    gsap.to(currentEl, {x:'-100%',ease:"easeSpe",duration:0.8})
    gsap.to(currentEl.querySelector('img'), {x:'90%',ease:"easeSpe",duration:0.8})

    gsap.to(progrBar, {scaleX:1/numSlides * (indexInParent(nextEl) + 1),ease:"easeSpe",duration:0.8})

    gsap.fromTo(nextEl.querySelector('img'), {x:'10%'},{x:'0%',ease:"easeSpe",duration:0.8})

    currentEl.classList.remove('current')
    nextEl.classList.add('current')

    el.querySelector('.numGauche span').innerHTML = indexInParent(nextEl) + 1;
}









// temp
// document.addEventListener('keypress', logKey)
// function logKey(e) {
//     if(e.code=='Space') {
//         e.preventDefault();
//         if(document.querySelector('body').classList.contains('dev')) document.querySelector('body').classList.remove('dev');
//         else document.querySelector('body').classList.add('dev');
//     }
// }




