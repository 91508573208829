// libs
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import {indexInParent, splitLines} from "utils";


export default class Carousel
{
	constructor(elmt) {
		this.elmt = elmt;
		this.currentPos = 0;
		this.nbCartes = elmt.querySelectorAll('.leCarou .innerImg').length;
        this.numeroSlide = 0;
        this.onUpdateLesPuces = true;
        this.animEnCours = false;
    }

    actions () {

        this.elmt.querySelector('.toSlide').classList.add('passe')

        // document.querySelectorAll('.entree h3').
        document.querySelectorAll('.entree h3').forEach(el => {
            splitLines(el, '<span><span>', '</span></span>')
        });

        gsap.to(document.querySelectorAll('.entree')[0].querySelectorAll('h3 span span'), {
            duration:0.8,y:'0%',delay:0.3,stagger:0.07,ease:'easeSpe',
            scrollTrigger:{
                trigger: document.querySelectorAll('.entree')[0],
                start: 'top bottom'
            }
        })
        gsap.fromTo(document.querySelectorAll('.entree')[0].querySelector('p'), {
            opacity:0
        },{
            duration:0.4,opacity:1,delay:0.6,ease:'easeSpe',
            scrollTrigger:{
                trigger: document.querySelectorAll('.entree')[0],
                start: 'top bottom'
            }
        })

    	// this.elmt.querySelector('.numTotalSlide span').innerHTML = this.nbCartes;
    	ScrollTrigger.create({
            trigger: this.elmt.querySelector('.pushCarousel'),
            start: "top top+=" + 136, // 126 header + gutter 
            // start: "top top", // 126 header + gutter 
            end:'+=' + ((this.nbCartes - 1) * window.innerHeight),
            pin: true
        });

    	let incr = -136;
    	let elmts = [];
    	let first = 0;

        this.elmt.querySelectorAll('.leCarou .innerImg').forEach(el => {
        	if(first == 0){
        		first++;
        	}else{
        		elmts.push(el);
        	}
        })

        elmts.forEach(el => {

            ScrollTrigger.create({
                trigger : this.elmt.querySelector('.innerCarou'),
                start:'top top-=' + (incr + (window.innerHeight/2)) + "px",
                end:'+=' + window.innerHeight + "px",
                onEnter: () => {

                    this.numeroSlide++;
                    // this.elmt.querySelector('.numCurrentSl span').innerHTML = this.numeroSlide + 1;

                    if(this.onUpdateLesPuces){
                        gsap.to(this.elmt.querySelector('.bubble'), {
                            x: this.numeroSlide * 100 + '%',
                            duration:0.5,
                            ease:"easeSpe"
                        })

                        gsap.to(this.elmt.querySelectorAll('.lesImgs img'), {
                            x: -1 * this.numeroSlide * 100 + '%',
                            ease:'easeSpe',
                            duration:0.5,
                            // onComplete:() => {
                            //     this.updatePuces();
                            // }
                        })
                    }

                    gsap.killTweensOf(this.elmt.querySelectorAll('.entree'));
                    gsap.to(this.elmt.querySelectorAll('.entree'), {
                        duration:0.2,
                        opacity:0,
                        ease:'none'
                    })
                    gsap.to(this.elmt.querySelectorAll('.entree')[indexInParent(el)], {
                        duration:0.2,
                        opacity:1,
                        delay:0.2,
                        ease:'none',
                        onComplete:() => {
                            this.updatePuces();
                        }
                    })

                    gsap.fromTo(this.elmt.querySelectorAll('.entree')[indexInParent(el)].querySelectorAll('h3 span span'), {
                        y:'100%'
                    },{
                        duration:0.8,y:'0%',delay:0.2,stagger:0.07,ease:'easeSpe'
                    })

                    gsap.fromTo(this.elmt.querySelectorAll('.entree')[indexInParent(el)].querySelector('p'), {
                        opacity:0
                    },{
                        duration:0.4,opacity:1,delay:0.6,ease:'easeSpe'
                    })
                },
                onLeaveBack: () => {

                    this.numeroSlide--;
                    // this.elmt.querySelector('.numCurrentSl span').innerHTML = this.numeroSlide + 1;

                    if(this.onUpdateLesPuces){
                        gsap.to(this.elmt.querySelector('.bubble'), {
                            x: this.numeroSlide * 100 + '%',
                            duration:0.5,
                            ease:"easeSpe"
                        })

                        gsap.to(this.elmt.querySelectorAll('.lesImgs img'), {
                            x: -1 * this.numeroSlide * 100 + '%',
                            ease:'easeSpe',
                            duration:0.5
                        })
                    }

                    gsap.killTweensOf(this.elmt.querySelectorAll('.entree'));
                    gsap.to(this.elmt.querySelectorAll('.entree'), {
                        duration:0.2,
                        opacity:0,
                        ease:'none'
                    })
                    gsap.to(this.elmt.querySelectorAll('.entree')[indexInParent(el) - 1], {
                        duration:0.2,
                        opacity:1,
                        delay:0.2,
                        ease:'none'
                    })
                    gsap.fromTo(this.elmt.querySelectorAll('.entree')[indexInParent(el) - 1].querySelectorAll('h3 span span'), {
                        y:'100%'
                    },{
                        duration:0.8,y:'0%',delay:0.3,stagger:0.07,ease:'easeSpe'
                    })

                    gsap.fromTo(this.elmt.querySelectorAll('.entree')[indexInParent(el) - 1].querySelector('p'), {
                        opacity:0
                    },{
                        duration:0.4,opacity:1,delay:0.6,ease:'easeSpe'
                    })

                    this.updatePuces();
                }
            })

            gsap.to(el, {
        		y:'0%',
        		ease:"none",
        		scrollTrigger: {
        			trigger	: this.elmt.querySelector('.innerCarou'),
        			start:'top top-=' + incr + "px",
        			end:'+=' + window.innerHeight + "px",
        			scrub: true,
        		}
        	})
        	gsap.to(el.querySelector('.img'), {
        		y:'0%',
        		ease:"none",
        		scrollTrigger: {
        			trigger	: this.elmt.querySelector('.innerCarou'),
        			start:'top top-=' + incr + "px",
        			end:'+=' + window.innerHeight + "px",
        			scrub: true
        		}
        	})
            gsap.to(el.querySelector('.img'), {
                y:'0%',
                ease:"none",
                scrollTrigger: {
                    trigger : this.elmt.querySelector('.innerCarou'),
                    start:'top top-=' + incr + "px",
                    end:'+=' + window.innerHeight + "px",
                    scrub: true
                }
            })
            gsap.to(el.querySelector('img'), {
                y:'-15%',
                ease:"none",
                scrollTrigger: {
                    trigger : this.elmt.querySelector('.innerCarou'),
                    start:'top top-=' + incr + "px",
                    end:'+=' + window.innerHeight*3 + "px",
                    scrub: true
                }
            })

        	incr+= window.innerHeight;
        })

        // cas premiere image
        gsap.to(this.elmt.querySelector('.img img'), {
            y:'-15%',
            ease:"none",
            scrollTrigger: {
                trigger : this.elmt.querySelector('.innerCarou'),
                start:'top bottom',
                end:'+=' + window.innerHeight*3 + "px",
                scrub: true
            }
        })

        console.log(this.elmt.querySelectorAll('.toSlide'))

    	this.elmt.querySelectorAll('.toSlide').forEach(el => {
    		el.addEventListener('click', () => {
                this.animEnCours = true;
    			let debutSection = this.elmt.getBoundingClientRect().top + window.pageYOffset;

				// window.scrollTo(0, this.elmt.getBoundingClientRect().top + window.pageYOffset + indexInParent(el) * window.innerHeight + 1)
                this.onUpdateLesPuces = false;

				let progress = {}
				progress.scroll = window.pageYOffset
				gsap.to(progress, {
					scroll: this.elmt.getBoundingClientRect().top + window.pageYOffset + indexInParent(el) * window.innerHeight - 135, // 136 - 1 // 1 = pixel supplémentaire pour arrondir
					ease:"power4.inOut",
					duration:1.4,
					onUpdate: () => {
						window.scrollTo(0, progress.scroll);
					},
                    onComplete: () => {
                        this.onUpdateLesPuces = true
                        this.animEnCours = false;
                    }
				})

    		})

            el.addEventListener('mouseenter', () => {
                console.log(indexInParent(el))
                gsap.to(this.elmt.querySelector('.bubble'), {
                    x: '100' * indexInParent(el) + "%",
                    ease:'easeSpe',
                    duration:0.5
                })
                gsap.to(this.elmt.querySelectorAll('.lesImgs img'), {
                    x: -1 * indexInParent(el) * 100 + '%',
                    ease:'easeSpe',
                    duration:0.5
                })

                this.elmt.querySelector('.bubble').classList.add('gros');
            })
            el.addEventListener('mouseleave', () => {
                if(!this.animEnCours){
                    gsap.to(this.elmt.querySelector('.bubble'), {
                        x: '100' * this.numeroSlide + "%",
                        ease:'easeSpe',
                        duration:0.5
                    })
                    gsap.to(this.elmt.querySelectorAll('.lesImgs img'), {
                        x: -1 * this.numeroSlide * 100 + '%',
                        ease:'easeSpe',
                        duration:0.5
                    })
                }
                this.elmt.querySelector('.bubble').classList.remove('gros');
            })
    	})

        
    }

    updatePuces() {
        this.elmt.querySelectorAll('.toSlide').forEach(el => {
            el.classList.remove('passe')
        })
        for(let i = 0; i <= this.numeroSlide; i++){
            this.elmt.querySelectorAll('.toSlide')[i].classList.add('passe');
        }
    }

}