// import
import gsap from "gsap"

// libs
export default class Slider
{
	constructor(elmt) {
        this.el = elmt;

        const ball = this.el.querySelector('.bulle');

        this.el.addEventListener('mouseenter', () => {
            this.el.classList.add('actif');


            gsap.ticker.add(this.addTicker);
        })
        this.el.addEventListener('mouseleave', () => {
            this.el.classList.remove('actif');
            
            // this.xSet(0.5*window.innerWidth);
            // this.ySet(0.5*window.innerHeight);

            gsap.delayedCall(0.5, () => {
                if(!this.el.classList.contains('actif')){
                    gsap.ticker.remove(this.addTicker);

                    gsap.to(ball, {
                        x:0.5*window.innerWidth + "px",
                        y:0.5*window.innerHeight + "px",
                        ease:"easeSpe",
                        duration:0.5
                    })
                }
            })

            
        })

        this.addTicker = () => {
            this.playTicker();
        }


        gsap.set(ball, {xPercent: -50, yPercent: -50});

        
        this.pos = { x: 0, y: 0 };

        this.xSet = gsap.quickSetter(ball, "x", "px");
        this.ySet = gsap.quickSetter(ball, "y", "px");

        // this.el.querySelector('.elPara').addEventListener('click', () => {
        //     this.el.parentElement.querySelector('.fullVid').classList.add('on');
        //     this.el.parentElement.querySelector('.fullVid video').play();
        // })
    }

    playTicker(){
        const dt = 1.0 - Math.pow(0.9, gsap.ticker.deltaRatio());
        
        this.pos.x += (mouse.x - this.pos.x - this.el.getBoundingClientRect().left) * dt;
        this.pos.y += (mouse.y - this.pos.y - this.el.getBoundingClientRect().top) * dt;
        this.xSet(this.pos.x);
        this.ySet(this.pos.y);
    }

}