// import
import Lottie from "lottie-web"

// libs
export default class LottieAnimations
{
	constructor(elmt) {
        this.el = elmt;
		this.monAnim;
    }

    actions () {
        console.log('tm', this.el, this.el.querySelector('span.flex'))
        this.monAnim = Lottie.loadAnimation({
            container: this.el.querySelector('span.flex'), // the dom element that will contain the animation
            renderer: 'svg',
            autoplay: false,
            loop:false,
            path: this.el.getAttribute('data-lottie-spe-url') // the path to the animation json
        });

        this.monAnim.setSpeed(2);

        this.el.addEventListener('mouseenter', () => {
            // this.monAnim.setDirection(1);
            setTimeout(() => {
                this.monAnim.playSegments([0, 180], true);
            }, 100)
            
        })
        // this.el.addEventListener('mouseleave', () => {
        //     //this.monAnim.setDirection(-1);
        //     this.monAnim.playSegments([94, 180], true);
        // })
        
    }

}