import Lottie from "lottie-web"

export const lottieBurger = Lottie.loadAnimation({
    container: document.querySelector('.toMenu'), // the dom element that will contain the animation
    renderer: 'svg',
    autoplay: false,
    loop:false,
    path: document.querySelector('.toMenu').getAttribute('data-lottie') // the path to the animation json
});

export let isPopUpAnimating = {state:false};