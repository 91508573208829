// import
import Lottie from "lottie-web"

// libs
export default class LottieAnimations
{
	constructor(elmt) {
        this.el = elmt;
		this.monAnim;
    }

    actions () {
        this.monAnim = Lottie.loadAnimation({
            container: this.el, // the dom element that will contain the animation
            renderer: 'svg',
            autoplay: false,
            loop:false,
            path: this.el.getAttribute('data-lottie-url') // the path to the animation json
        });

        this.monAnim.setSpeed(2);

        if(this.el.classList.contains('innerLottiePM')){
            this.el.closest('.explore').addEventListener('click', () => {
                if(this.el.closest('.explore').classList.contains('actif')){
                    this.monAnim.playSegments([180, 0], true);
                }else{
                    this.monAnim.playSegments([90, 180], true);
                }
            })

            this.el.closest('.explore').addEventListener('mouseenter', () => {
                if(!this.el.closest('.explore').classList.contains('actif')){
                    this.monAnim.playSegments([0, 90], true);
                }
            })
            this.el.closest('.explore').addEventListener('mouseleave', () => {
                if(!this.el.closest('.explore').classList.contains('actif')){
                    this.monAnim.playSegments([90, 0], true);
                }
            })

        }else{
            this.el.addEventListener('mouseenter', () => {
                this.monAnim.playSegments([0, 94], true);
            })
            this.el.addEventListener('mouseleave', () => {
                this.monAnim.playSegments([94, 180], true);
            })
        }
        
    }

}