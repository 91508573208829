// libs
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger"

import {splitLines, getAllSiblings, indexInParent, appHeight, isTouch} from "utils";

import Carte from "Carte"
import Carousel from "Carousel"
import LottieAnimations from "Lottie"
import LottieAnimationsSpe from "LottieSpe"
import Player from "Player"
import Slider from "Slider"

import {lottieBurger} from "./constants";
// import HoverNewsletter from "HoverNewsletter"

// modules
// import {GSAPScroll} from "./GSAPScroll";
// import {indexInParent, isMobile} from "./utils";

// constants
// import {scroll, animsEntree} from './constants';

window.mouse = {x:0, y:0};
window.addEventListener("mousemove", e => {    
	window.mouse.x = e.x;
	window.mouse.y = e.y;  
});


export default class Transition
{
	constructor(elmt) {
		this.currentStep = 0;
        this.allSteps = 2; //3

        this.newClass;
        this.newTitle;
        this.newDOM;
        this.newNav;

        this.animEnCours = true;
    }

    start (url, debut) {

    	if(debut == true){
    		this.allSteps = 3; 
    		this.playIntro();
    	}

        this.animEnCours = true;

    	this.animationSortie();
    	this.changementDOM(url);
    }

    // STEP
    animationSortie() {

    	gsap.set('.uneIntro .volet, .uneIntro h2', {y:'100%'})
    	if(document.body.classList.contains('toProvider')){
    		document.querySelector('.introProvider').classList.add('actif');
    		this.transitionSpe();
    	}else if(document.body.classList.contains('toPatient')){
    		document.querySelector('.introPatient').classList.add('actif');
    		this.transitionSpe();
    	}else if(document.body.classList.contains('toPlan')){
    		document.querySelector('.introPlans').classList.add('actif');
    		this.transitionSpe();
    	}else if(document.body.classList.contains('toHome')){
    		document.querySelector('.introHome').classList.add('actif');
    		this.transitionSpe();
    	}else if(document.body.classList.contains('toStories')){
    		document.querySelector('.introStories').classList.add('actif');
    		this.transitionSpe();
    	}else if(document.body.classList.contains('toPage')){
    		document.querySelector('.introPage').classList.add('actif');
    		this.transitionSpe();
    	}else{
    		// temp (cas ou pas page spe ou cas premier arrivee)
    		this.killAllScroTrig();
		    this.incrementStep(); 
    	}
    	
    }


    killAllScroTrig() {
        let triggers = ScrollTrigger.getAll();
        triggers.forEach( trigger => {
          trigger.kill();
        });
    }

    // STEP
    changementDOM(url) {
    	this.ajaxPromise(url).then((data) => {
            let parser = new DOMParser();
            let doc = parser.parseFromString(data, "text/html");
            let liste_class = doc.body.getAttribute('class');
            
            // maj titre de la page
            this.newTitle = doc.querySelector('title').innerHTML;
            this.newNav = doc.querySelector('header').innerHTML;
			this.newClass = doc.body.getAttribute('class');
            this.newDOM = doc.getElementById('main').innerHTML;

            this.incrementStep();
            
        });

        //history.pushState({}, '', url);
    }

    // ALL STEPS COMPLETE
    animationEntree(titre, classes, DOM) {
        //reset
        window.scrollTo(0,0);

        document.querySelector('.fondH').classList.remove('actif');
        document.querySelector('header').classList.remove('cache2');
        document.querySelector('header').classList.remove('cache');

		this.currentStep = 0;
		this.animEnCours = false;

    	// maj titre, class, DOM page
        document.title = this.newTitle;
		document.body.setAttribute('class', this.newClass);
        document.getElementById('main').innerHTML = this.newDOM;
        document.querySelector('header').innerHTML = this.newNav;

        if(document.getElementById('loader').classList.contains('actif')){
        	if(document.body.classList.contains('home') || document.body.classList.contains('page-template-plans')){
        		document.getElementById('loader').classList.remove('actif')
        	}else{
        		gsap.to('#loader', {
	        		scaleY:0,
	        		ease:'easeSpe',
	        		duration:1,
	        		onComplete:() => {document.getElementById('loader').classList.remove('actif')}
	        	})
        	}
        	gsap.delayedCall(0.2, () => {
        		document.querySelector('header').classList.add('intro');
        		document.querySelector('.headerMob').classList.add('intro');
        	})
        	// gsap.to('header', {
        	// 	y:'0%',
        	// 	ease:'easeSpe',
        	// 	duration:1,
        	// 	delay:0.2
        	// })
        }

        if(document.querySelector('.uneIntro.actif h2')){
        	gsap.to('.uneIntro.actif h2', {y:'-100%', ease:"easeSpe", duration:0.75});
        }

        if(document.querySelector('.uneIntro.actif')){
	        gsap.to('.uneIntro.actif .volet', {y:'-100%', ease:"easeSpe", duration:1.5, onComplete:() => {
	        	document.querySelector('.uneIntro.actif')?.classList.remove('actif')
	        }});
	    }

        this.methodsTenzr();

        // petit effet de style menu point qui disparait quand un autre hover
        document.querySelectorAll('nav li').forEach(el => {
        	el.addEventListener('mouseenter', () => {
        		getAllSiblings(el).forEach(el => {
        			el.classList.add('off');
        		})
        	})
        	el.addEventListener('mouseleave', () => {
        		getAllSiblings(el).forEach(el => {
        			el.classList.remove('off');
        		})
        	})
        })
        
    }




    //
    //
    // util class
    //
    //
    incrementStep() {
    	this.currentStep++;
        // console.log(this.currentStep);
    	if(this.currentStep == this.allSteps) {
            this.animationEntree();
    	}
    }

    ajaxPromise (url) {
		return new Promise((resolve) => { //, reject
			const xhr = new XMLHttpRequest();
			xhr.open("GET", url);
			xhr.onload = () => resolve(xhr.responseText);
			// xhr.onerror = () => reject(xhr.statusText);
			xhr.send();
		});
	}




	transitionSpe() {
		if(document.querySelector('.uneIntro.actif h2')){
			gsap.to('.uneIntro.actif h2', {y:'0%', ease:"easeSpe", duration:0.75, delay:0.5});
		}
		gsap.fromTo('.uneIntro.actif .volet', {y:'100%'}, {y:'0%', ease:"easeSpe", duration:1.5, onComplete:() => {
			
			// reset menu
			document.querySelector('header').classList.remove('actif')
			document.querySelector('.headerMob .cache')?.classList.remove('cache')
	        gsap.set('header', {background:'rgba(30,28,33,0)'})
	        lottieBurger.goToAndStop(0);

			// global
	    	this.killAllScroTrig();
	        this.incrementStep();
	        // fin global
		}})
	}

	methodsTenzr () {
		//
		//
		// TRANSITION
		//
		//

		

		document.querySelectorAll('[data-lottie-url]').forEach(el => {
			const monLottie = new LottieAnimations(el);
			monLottie.actions();
		})
		document.querySelectorAll('[data-lottie-spe-url]').forEach(el => {
			const monLottie = new LottieAnimationsSpe(el);
			monLottie.actions();
		})

		const style = getComputedStyle(document.querySelector('footer'))

		const leBackgroundColor = style.backgroundColor
		
		document.querySelector('footer .cb circle').style.stroke = leBackgroundColor;
		document.querySelector('footer .formFoot .fond').style.background = leBackgroundColor;

		if(document.body.classList.contains('home')){
			document.querySelector('header a.cta').classList.add('blanc');
			document.querySelector('.headerMob button').classList.add('blanc');
			appHeight();
		}else{
			document.querySelector('header a.cta').classList.remove('blanc');
			document.querySelector('.headerMob button').classList.remove('blanc');
		}


		// temp debut
		gsap.delayedCall(0.2, () => {



			//
			// ---- init accordéons
			//
			document.querySelectorAll('[data-toggle-trigger]').forEach(el => {
				el.parentElement.setAttribute('data-hauteur',el.clientHeight)
				el.parentElement.style.height = el.clientHeight + "px"
			})


			//
			// ------ pin
			//

			if(document.querySelector('.scrollVid')){

				// alert()

				// on fixe la partie séquence
				ScrollTrigger.create({
		            trigger: ".scrollVid",
		            start: "top top", 
		            end:'+=' + (2* window.innerHeight) + 'px',
		            pin: true
		        });

		        if(window.innerWidth < 768){
		        	gsap.to('.hack, .innerMain', {
		        		y:'0%',
		        		ease:'none',
		        		scrollTrigger:{
		        			trigger: ".scrollVid",
				            start: "top top", 
				            end:'+=' + (2* window.innerHeight) + 'px',
				            scrub:true
		        		}
		        	})
		        }



				// on fait défiler les images de la séquence
				let currentProgress = 0;
				ScrollTrigger.create({
		            trigger: ".scrollVid",
		            start: "top 50%", 
		            end:'+=' + (2.5* window.innerHeight),
		            
		            onUpdate: self => {
		                let calc = Math.round(self.progress * 299);
		                if(calc != currentProgress){
		                	document.querySelector('.sequence .current').classList.remove('current');
		                	document.querySelectorAll('.sequence picture')[calc].classList.add('current');
		                    currentProgress = calc;
		                }
		            },
		            onLeave: () => {
		            	if(window.innerWidth > 768){
		            		gsap.set('.fondV .fond .last', {
		            			x: gsap.getProperty('.sequence', "x"),
		            			y: gsap.getProperty('.sequence', "y")
		            		})
			            	document.querySelectorAll('.last').forEach(el => {
			            		el.classList.add('off');
			            	});
			            }else{
			            	document.querySelector('.fondV .fond').classList.add('mob')
			            }
		            },
		            onEnterBack: () => {
		            	if(window.innerWidth > 768){
			            	document.querySelectorAll('.last').forEach(el => {
			            		el.classList.remove('off');
			            	});
			            }else{
			            	document.querySelector('.fondV .fond').classList.remove('mob')
			            }
		            }
		        });

				if(window.innerWidth > 768){
			        ScrollTrigger.create({
			            trigger: ".scrollVid",
			            start: "top top", 
			            end:'+=' + (3* window.innerHeight),
			            onEnterBack:() => {
			            	gsap.killTweensOf('.fondV h3 span span');
			            	gsap.killTweensOf('.fondV p');
			            	gsap.set('.fondV p', {opacity:0})
			            	gsap.fromTo('.fondV h3 span span', {
			            		y:'-100%'
			            	},{
			            		y:'0%',
			            		duration:1.3,
								stagger:-0.2,
								ease:"easeSpe",
			            	})
			            	gsap.to('.fondV p', {opacity:1, duration:1, delay:1, ease:'none'})
			            }
			        });

			        ScrollTrigger.create({
			            trigger: ".fondV",
			            start: "top bottom", 
			            onEnter:() => {
			            	gsap.killTweensOf('.fondV h3 span span');
			            	gsap.killTweensOf('.fondV p');
			            	gsap.set('.fondV p', {opacity:0})
			            	gsap.fromTo('.fondV h3 span span', {
			            		y:'100%'
			            	},{
			            		y:'0%',
			            		duration:1.3,
								stagger:0.2,
								ease:"easeSpe",
			            	})
			            	gsap.to('.fondV p', {opacity:1, duration:1, delay:1, ease:'none'})
			            }
			        });
			    }

		        
		    	// FIN BUG
		    	if(window.innerWidth > 768){
			        gsap.to('.sequence', {
			        	x:-0.17 * window.innerWidth,
			        	y:0.5 * window.innerHeight - 0.252*window.innerWidth,
			        	scrollTrigger: {
			        		scrub:true,
			        		trigger: ".scrollVid",
			            	start: "top top", 
			            	end:'+=' + (2* window.innerHeight)
			        	}
			        })
			    }else{
			    	gsap.to('.sequence', {
			        	y:((document.querySelector('.scrollVid').clientHeight)/2 - 0.54 * window.innerWidth) + 'px' ,
			        	// ease:'none',
			        	scrollTrigger: {
			        		scrub:true,
			        		trigger: ".scrollVid",
			            	start: "top top", 
			            	end:'+=' + (2* window.innerHeight)
			        	}
			        })
			    }

		        gsap.to('.fond img', {
		        	scale:1,
		        	ease:"power1.out",
		        	scrollTrigger: {
		        		trigger:'.fondV',
		        		start:'top bottom',
		        		end:'+=' + (window.innerHeight),
		        		scrub:true
		        	}
		        })

		        // BUG ICI
		        // gsap.to('.sequence .last', {
		        // 	y:'70vh',
		        // 	ease:'none',
		        // 	immediateRender:false,
		        // 	scrollTrigger:{
		        // 		trigger:'.fondV',
		        // 		start:'top top',
		        // 		end:'+=' + window.innerHeight,
		        // 		scrub:true
		        // 	}
		        // })
		        if(!isTouch()){
			        gsap.to('.fondV .fond', {
			        	y:'-30vh',
			        	ease:'none',
			        	immediateRender:false,
			        	scrollTrigger:{
			        		trigger:'.fondV',
			        		start:'top top',
			        		end:'+=' + window.innerHeight,
			        		scrub:true
			        	}
			        })
			    }

		    }



			//
			// ------ global
			//

			// form
			document.querySelectorAll('select').forEach(el => {
				el.addEventListener('change', () => {
					el.classList.remove('debut')
				})
			})


			if(document.querySelector('.liPlus')){
				gsap.to('.liPlus li', {
					opacity:1,
					y:0,
					ease:'easeSpe',
					duration:1,
					stagger:0.06,
					scrollTrigger:{
						trigger:'.liPlus',
						start:'top 80%',
					}
				})
			}

			if(document.querySelectorAll('.offre')[1] && window.innerWidth > 768){
				gsap.fromTo(document.querySelectorAll('.offre')[1], {
					y:'20%'
				}, {
					y:'0%',
					ease:"easeSpe",
					duration:1.4,
					scrollTrigger:{
						trigger:'.prix',
						start:'top 80%',
					}

				})
			}



			// split
			document.querySelectorAll('.split').forEach(el => {
				el.classList.remove('off');
				splitLines(el, '<span><span>', '</span></span>')

				const spans = el.querySelectorAll('span span')

				let incr = 0;
				spans.forEach(ligne => {
					gsap.to(ligne, {
						y:'0%',
						duration:1.4 + incr,
						ease:"easeSpe",
						scrollTrigger: {
							trigger:el,
							start:'top 120%'
						}
					})
					incr += 0.3;
				})

				// gsap.to(spans, {
				// 	y:'0%',
				// 	duration:1.3,
				// 	stagger:0.3,
				// 	ease:"easeSpe",
				// 	scrollTrigger: {
				// 		trigger:el,
				// 		start:'top bottom'
				// 	}
				// })
			});

			// parallax
			document.querySelectorAll('.para').forEach(el => {
				const lePara = el.querySelector('.elPara');

				let dist = 30;
				if(window.innerWidth <= 768){
					dist = 10
				}
				if(el.classList.contains('smallPara')){
					gsap.fromTo(lePara, {
						y:'-10%',
						scaleX:1.05,
						scaleY:1.05
					},{
						y:'10%',
						ease:"none",
						scrollTrigger: {
							trigger:el,
							start:'top bottom',
							end:'bottom top',
							scrub: true,
							onEnter: () => {
								console.log('enter')
							},
							onLeave: () => {
								console.log('finit')
							},
						}
					})
				}else{
					gsap.to(lePara, {
						y:dist + '%',
						ease:"none",
						scrollTrigger: {
							trigger:el,
							start:'top bottom',
							end:'bottom top',
							scrub: true,
							onEnter: () => {
								console.log('enter')
							},
							onLeave: () => {
								console.log('finit')
							},
						}
					})
				}
			});

			// testi
			document.querySelectorAll('.testi').forEach(el => {
				const lePara = el.querySelectorAll('.innerAnim');
				gsap.to(lePara, {
					y:'0%',
					ease:"easeSpe",
					duration:1.4,
					stagger:'-0.2',
					scrollTrigger: {
						trigger:el,
						start:'top bottom'
					}
				})
			});


			//
			// ------ NON GLOBAL
			//

			// home
			if(document.body.classList.contains('home')){

				ScrollTrigger.create({
					trigger:'#main',
					start:'top top',
					onEnter: () => {
						document.querySelector('.fleche').classList.add('off')
					},
					onLeaveBack: () => {
						document.querySelector('.fleche').classList.remove('off')
					}
				})
				gsap.to('.img1, .img1 .innerCover', {
					yPercent:0,
					y:'0%',
					duration:1.4,
					ease:"easeSpe"
				})

				gsap.to('.innerCover2', {
					y:'-15%',
					ease:'none',
					scrollTrigger:{
						trigger:'.hero',
						start:'top top',
						end:'bottom top',
						scrub:true
					}
				})

				gsap.to('.img1 .cover', {
					y:'20%',
					ease:"none",
					scrollTrigger:{
						trigger:'.hero',
						start:'top top',
						end:'bottom top',
						scrub:true
					}
				})

				if(window.innerWidth > 768){
					gsap.to('.duo .sec', {
						y:'-20%',
						ease:"none",
						scrollTrigger: {
							trigger:'.duo',
							start:'top bottom',
							end:'bottom top',
							scrub:true
						}
					})
				}

				// gsap.to('.triggVid', {
				// 	scrollTrigger: {
				// 		trigger:'.triggVid',
				// 		start:'top bottom',
				// 		onEnter: () => {
				// 			document.querySelector('.triggVid video').play();
				// 		}
				// 	}
				// })

				// ScrollTrigger.create({
				// 	trigger:'.hero',
				// 	start:'top top',
				// 	end:'bottom 20%',
				// 	onEnterBack: () => {
				// 		// document.querySelector('header .cta').classList.add('blanc')
				// 		//document.querySelector('.headerMob button').classList.add('blanc');
				// 	},
				// 	onLeave:() => {
				// 		// document.querySelector('header .cta').classList.remove('blanc')
				// 		//document.querySelector('.headerMob button').classList.remove('blanc');
				// 	}
				// })

				// ScrollTrigger.create({
				// 	trigger:'.player',
				// 	start:'top 5%',
				// 	end:'bottom 5%',
				// 	onEnterBack: () => {
				// 		// document.querySelector('header .cta').classList.add('blanc')
				// 		document.querySelector('.headerMob button').classList.add('blanc');
				// 	},
				// 	onLeave:() => {
				// 		// document.querySelector('header .cta').classList.remove('blanc')
				// 		document.querySelector('.headerMob button').classList.remove('blanc');
				// 	},
				// 	onEnter: () => {
				// 		// document.querySelector('header .cta').classList.add('blanc')
				// 		document.querySelector('.headerMob button').classList.add('blanc');
				// 	},
				// 	onLeaveBack:() => {
				// 		// document.querySelector('header .cta').classList.remove('blanc')
				// 		document.querySelector('.headerMob button').classList.remove('blanc');
				// 	}
				// })

			}

			if(document.body.classList.contains('page-template-plans') || document.body.classList.contains('blog')){
				gsap.to('.img1, .img1 .innerCover', {
					yPercent:0,
					y:'0%',
					duration:1.4,
					ease:"easeSpe"
				})


				gsap.to('.img1 .cover', {
					y:'15%',
					ease:"none",
					scrollTrigger:{
						trigger:'.heroPlans',
						start:'top top',
						end:'bottom top',
						scrub:true
					}
				})
			}
			if(document.body.classList.contains('page-template-provider') || document.body.classList.contains('page-template-patient') || document.body.classList.contains('page-template-modules')){
				gsap.to('.img1, .img1 .innerCover', {
					yPercent:0,
					y:'0%',
					duration:1.4,
					ease:"easeSpe"
				})
				gsap.to('.img1 .cover', {
					y:'15%',
					ease:"none",
					scrollTrigger:{
						trigger:'.hero',
						start:'top top',
						endTrigger:'.img1',
						end:'bottom top',
						scrub:true
					}
				})
			}

			if(document.body.classList.contains('page-template-modules')){
				document.querySelector('footer').style.backgroundColor = document.querySelector('.avantFtr').getAttribute('data-fond');
				document.querySelector('footer .email .fond').style.backgroundColor = document.querySelector('.avantFtr').getAttribute('data-fond');
				document.querySelectorAll('#modules .unMod').forEach(el => {
					if(el.previousElementSibling !== null){
						if(el.previousElementSibling.getAttribute('data-fond') == el.getAttribute('data-fond')){
							el.classList.add('trait')
						}
					}
				})
			}

			if(document.body.classList.contains('page-template-patient')) {
				document.querySelectorAll('.picto').forEach(el => {
					ScrollTrigger.create({
						trigger:el,
						start:'bottom 80%',
						onEnter: () => {
							el.classList.add('actif')
						}
					})
				})
			}

			if(document.body.classList.contains('single')) {
				document.querySelectorAll('.carou').forEach(el => {

					const slider = new Slider(el);

					gsap.set(el.querySelector('.progressBar div',), {
						scaleX: 1/el.querySelectorAll('.innerImg').length
					})
					el.querySelector('.num').innerHTML = el.querySelectorAll('.innerImg').length;
				})
			}


		})

		document.querySelectorAll('.pushCartes').forEach(el => {
			const cartes = new Carte(el);
			cartes.actions();

			if(document.body.classList.contains('page-template-modules')){
				el.querySelectorAll('.innerLottie').forEach(dom => {
					dom.style.background = el.style.background;
				})
				el.querySelectorAll('.cb circle').forEach(dom => {
					dom.style.stroke = el.style.background;
				})
			}
		})

		document.querySelectorAll('.parentCarou').forEach(el => {
			if(window.innerWidth > 768){
				const carou = new Carousel(el);
				carou.actions();
			}else if(el.querySelector('.numTotalSlide span')){
				el.querySelector('.numTotalSlide span').innerHTML = el.querySelectorAll('.entree').length;
			}
		})

		let numHover = 0;
		let tableauTL = [];
		if(document.querySelector('.pushTesti')){
			document.querySelectorAll('.pushTesti').forEach(sct => {
				sct.querySelectorAll('.pushBlessure').forEach(el => {
					let tl = gsap.timeline({paused:true});

					tl.to(el.querySelector('.plus'), {rotate:90, opacity:0, ease:"easeSpe", duration:0.5}, 0);
					tl.to(el.querySelectorAll('.text div'), {
						stagger:0.1,
						ease:"easeSpe",
						y:'0%',
						opacity:1,
						duration:0.8
					}, "-=0.5")
					
					tableauTL.push(tl);

					el.addEventListener('mouseenter', () => {
						apparitionDatas(tableauTL[indexInParent(el)]);
					})
					el.addEventListener('mouseleave', () => {
						disparitionDatas(tableauTL[indexInParent(el)]);
					})
					numHover++;
				})

			})
		}

		if(document.querySelector('.playButton')){
			document.querySelectorAll('.playButton').forEach(el => {
				const player = new Player(el);
				player.actions();
			})
		}
	}


	playIntro() {

		if(document.body.classList.contains('home')){
			document.querySelector('header a.cta').classList.add('blanc');
			document.querySelector('.headerMob button').classList.add('blanc');
			appHeight();
		}else{
			document.querySelector('header a.cta').classList.remove('blanc');
			document.querySelector('.headerMob button').classList.remove('blanc');
		}
		
		gsap.delayedCall(0.2, () => {
	        gsap.to('#loader img', {
	            opacity:1,
	            duration:1,
	            ease:'none',
	        })
	        gsap.to('#loader h2 span', {
	            stagger:1,
	            duration:1,
	            y:'0%',
	            ease:'easeSpe'
	        })
	        gsap.to('#loader h2 span', {
	            stagger:1,
	            duration:1,
	            y:'-100%',
	            ease:'easeSpe',
	            delay:1
	        })
	        gsap.to('#loader img', {
	            opacity:0,
	            duration:1,
	            ease:'none',
	            delay:4
	        })
	        gsap.to('#loader .fond', {
	            scaleY:0,
	            ease:'easeSpe',
	            duration:6
	        })
	    });
	    gsap.delayedCall(5.8, () => {
	        this.incrementStep();
	        this.allSteps = 2;
	    })
	}
}







function apparitionDatas(tl) {
	tl.play();
}
function disparitionDatas(tl) {
	tl.reverse();
}